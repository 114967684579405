@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.ui {
    font-family: 'Titillium Web' !important;
}

.main.container {
    margin-top: 6em;
}

.footer {
    margin-top: 2em;
}

#progress-spinner-mini {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

#middle-circle-mini {
    position: absolute;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    background-color: rgb(248, 248, 248);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125em;
    font-weight: bold;
}

#progress-spinner {
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

#middle-circle {
    position: absolute;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: rgb(248, 248, 248);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: bold;
}

.outerchart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.innerchart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smaller {
    color: #bdc3c7;
    font-size: 0.6em;
    padding-bottom: 8px;
    vertical-align: super;
}

.higher {
    vertical-align: super;
    padding-bottom: 8px;
}

.progress-label {
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
}

.ui.grid.tight > .row {
    padding-top: 0px;
    padding-bottom: 0px;
}

.ui.grid.tight > .column:not(.row), .ui.grid > .row > .column {
    padding-left: 0rem;
    padding-right: 0rem;
}

.ui.grid.tight {
    margin-bottom: 1em;
}

.miniIcon{
    max-width: 20px;
    max-height: 20px;
}

.headerIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

td.last {
    background: rgba(0,0,0,.03);
    font-weight: 700;
    color: rgba(0,0,0,.95);
    text-transform: '';
    box-shadow: '';
    text-align: '';
    font-size: 1em;
    padding-left: '';
    padding-right: ''
}